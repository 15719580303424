import { Component, Output, EventEmitter, Input } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors } from '@angular/forms';


@Component({
    selector: 'app-file-upload',
    templateUrl: './file-upload.component.html',
    styleUrls: ['./file-upload.component.scss'],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        multi: true,
        useExisting: FileUploadComponent
    },
    {
        provide: NG_VALIDATORS,
        multi: true,
        useExisting: FileUploadComponent
    }]
})
export class FileUploadComponent implements ControlValueAccessor {
    
    FileUri:string;
    FileName:string;
    File: any = null;
    loading = false;

    onChange = (_) => { };
    onTouched = () => { };
    touched = false;
    disabled = false;

    @Output() fileSelected = new EventEmitter<{file:File,qid:number}>()
    @Output() fileDeleted = new EventEmitter()
    @Input() qid;
    @Input() buttonText;
    
    constructor() { }

    writeValue(obj: any): void {
        this.FileName=obj && obj.hasOwnProperty('FileName')?obj.FileName:null;
        this.FileUri=obj && obj.hasOwnProperty('FileUri')?obj.FileUri:null;
        this.File = obj&& obj.hasOwnProperty('File')?obj.File:null;
    }

    registerOnChange(onChange: any) {
        this.onChange = onChange;
    }

    registerOnTouched(onTouched: any) {
        this.onTouched = onTouched;
    }

    markAsTouched() {
        if (!this.touched) {
            this.onTouched();
            this.touched = true;
        }
    }

    setDisabledState(disabled: boolean) {
        this.disabled = disabled;
    }

    selectFile(event) {
        if (event.target.files.length > 0) {
            this.File = event.target.files
            this.markAsTouched();
            
            this.fileSelected.emit({file:this.File[0],qid:this.qid})
            let f=(<File>this.File[0]);
            this.FileName=f.name;
            this.File=f;
            this.onChange({
                'FileName':f.name,
                'FileUri':null,
                'File':f
            });
        }
        else {
            this.File = null
            //this.fileSelected.emit(null)
            this.fileSelected.emit({file:null,qid:this.qid})
            this.onChange(null);
        }
    }

    deleteFiles() {
        this.File = null
        this.FileName = '';
        //this.fileSelected.emit(null)
        this.fileSelected.emit({file:null,qid:this.qid})
        this.fileDeleted.emit()
        this.onChange(null);
    }

    validate(control: AbstractControl): ValidationErrors | null {
        return null;
    }
}

