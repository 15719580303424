import { Injectable } from '@angular/core';
import { CommonsProxyClient } from 'src/proxy/apiCommons/commons.proxy';
import { mapCountry } from './mappings/country.mappings';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Country } from '@app/models/Country';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class CountriesService {
  constructor(private commonsClient: CommonsProxyClient, private translate: TranslateService) {}

  public getCountries(): Observable<Country[]> {
    return this.commonsClient
      .commonsCountryGet()
      .pipe(
        map((countries) => countries.map((country) => mapCountry(country, this.translate.currentLang)))
      );
  }
}
