export enum Position {
  ComplianceOfficer,
  RiskAnalyst,
  Finance,
  Purchase,
  Other,
}

export const PositionValues = Object.values(Position).filter(value => !isNaN(+value)) as Position[];

export function mapPositionToTranslationKey(position: Position): string {
  switch (position) {
    case Position.ComplianceOfficer:
      return 'position.complianceOfficer';
    case Position.RiskAnalyst:
      return 'position.riskAnalyst';
    case Position.Finance:
      return 'position.finance';
    case Position.Purchase:
      return 'position.purchase';
    default:
      return 'position.other';
  }
}
