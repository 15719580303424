import { AuthenticationService } from '@app/services/authentication.service';

export function appInitializer(authenticationService: AuthenticationService) {
    if (localStorage.getItem('token')) {
        return () => new Promise(resolve => {
            // attempt to refresh token on app start up to auto authenticate
            authenticationService.refreshToken()
                .subscribe()
                .add(resolve);
        });
    } else {
        return () => new Promise((resolve) => resolve(true));
    }

}
