import {TranslateService} from '@ngx-translate/core';
import {MatPaginatorIntl} from '@angular/material/paginator';
import {Injectable} from '@angular/core';

@Injectable()
export class CustomMatPaginatorIntl extends MatPaginatorIntl {
  constructor(private translate: TranslateService) {
    super();
    this.translate.onLangChange.subscribe((e: Event) => {
      this.getAndInitTranslations();
    });

    this.getAndInitTranslations();
  }

  getAndInitTranslations() {
    this.translate.get(['TableNavigation.ITEMS_PER_PAGE.valor', 'TableNavigation.NEXT_PAGE.valor', 'TableNavigation.PREVIOUS_PAGE.valor', 'TableNavigation.OF_LABEL.valor',
    , 'TableNavigation.FIRST_PAGE.valor', 'TableNavigation.LAST_PAGE.valor']).subscribe(translation => {
      this.itemsPerPageLabel = translation['TableNavigation.ITEMS_PER_PAGE.valor'];
      this.nextPageLabel = translation['TableNavigation.NEXT_PAGE.valor'];
      this.previousPageLabel = translation['TableNavigation.PREVIOUS_PAGE.valor'];
      this.firstPageLabel = translation['TableNavigation.FIRST_PAGE.valor'];
      this.lastPageLabel = translation['TableNavigation.LAST_PAGE.valor'];
      this.changes.next();
    });
  }

 getRangeLabel = (page: number, pageSize: number, length: number) =>  {
    if (length === 0 || pageSize === 0) {
      return `0 / ${length}`;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
    //return `${startIndex + 1} - ${endIndex} / ${length}`;
    return `${startIndex + 1} - ${endIndex} ${this.translate.instant("TableNavigation.OF_LABEL.valor")} ${length}`;
  }
  
}