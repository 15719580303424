import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-custom-toast',
  templateUrl: './custom-toast.component.html',
  styleUrls: [ ]
})
export class CustomToastComponent {

  @Input() toast;
  
  constructor() { }
}
