export function canAccess(role: string | string[], userRoles: string | string[]) {
      if (!userRoles) {
        return false;
      }
      let roles = [];
      if (typeof role === 'string') {
        roles = [role];
      } else {
        roles = [...role];
      }  

      return stringOrArrayFind(roles, userRoles)
}

function stringOrArrayFind(roles: string[], profileRoles: string | string[]) {
    if (!!(<string[]>profileRoles).find) {
      return !!(<string[]>profileRoles).find(k => !!roles.find(r => r.toLocaleLowerCase() === k.toLocaleLowerCase()));
    } else {
      return !!roles.find(r => r.toLocaleLowerCase() === (<string>profileRoles).toLocaleLowerCase());
    }
}