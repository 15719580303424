import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-confirm-exit',
  templateUrl: './dialog-confirm-exit.component.html'
})
export class DialogConfirmExitComponent {
  constructor(
    public dialogRef: MatDialogRef<DialogConfirmExitComponent>,
  ) { }
}
