<!--botón seleccionar archivo-->
<div *ngIf="!disabled">
    <input #fileInput hidden="true" type="file" onclick="this.value=null" (change)="selectFile($event)"/>
    <button type="button" class="c-button c-button--file" (click)="fileInput.click()">
        <span class="c-button__ico" aria-hidden="true">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16.667 20">
                <path
                    d="M13.667,13.333a.833.833,0,0,1-.833.833H11.167v1.667a.833.833,0,1,1-1.667,0V14.167H7.833a.833.833,0,1,1,0-1.667H9.5V10.833a.833.833,0,1,1,1.667,0V12.5h1.667A.833.833,0,0,1,13.667,13.333Zm5-4.6v7.1A4.172,4.172,0,0,1,14.5,20H6.167A4.172,4.172,0,0,1,2,15.833V4.167A4.172,4.172,0,0,1,6.167,0H9.929a5.8,5.8,0,0,1,4.125,1.708l2.9,2.9a5.793,5.793,0,0,1,1.709,4.124ZM12.876,2.887A4.175,4.175,0,0,0,12,2.237v3.6a.833.833,0,0,0,.833.833h3.6a4.153,4.153,0,0,0-.651-.875ZM17,8.737c0-.137-.027-.269-.039-.4H12.833a2.5,2.5,0,0,1-2.5-2.5V1.706c-.135-.012-.267-.039-.4-.039H6.167a2.5,2.5,0,0,0-2.5,2.5V15.833a2.5,2.5,0,0,0,2.5,2.5H14.5a2.5,2.5,0,0,0,2.5-2.5Z"
                    transform="translate(-2)" />
            </svg>
        </span>
        {{ buttonText }}
    </button>
    <div class="c-file-upload">
        <!--nombre archivo que se sube-->
        <span class="c-file-upload__file">{{FileName}}</span>
        <!-- botón borrar archivo -->
        <button *ngIf="FileName" type="button" class="c-file-upload__button c-file-upload__delete" (click)="deleteFiles()">
            <span class="c-file-upload__button-ico" aria-hidden="true">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.667 20"
                    xmlns:xlink="http://www.w3.org/1999/xlink">
                    <path
                        d="M20.833,3.333H18.25A4.174,4.174,0,0,0,14.167,0H9.5A4.174,4.174,0,0,0,5.417,3.333H2.833A.833.833,0,0,0,2.833,5h.833V15.833A4.172,4.172,0,0,0,7.833,20h8A4.172,4.172,0,0,0,20,15.833V5h.833a.833.833,0,0,0,0-1.667ZM9.5,1.667h4.667a2.5,2.5,0,0,1,2.358,1.667H7.142A2.5,2.5,0,0,1,9.5,1.667Zm8.833,14.167a2.5,2.5,0,0,1-2.5,2.5h-8a2.5,2.5,0,0,1-2.5-2.5V5h13Z"
                        transform="translate(-2)" />
                    <path
                        d="M9.833,16.667a.833.833,0,0,0,.833-.833v-5a.833.833,0,0,0-1.667,0v5A.833.833,0,0,0,9.833,16.667Z"
                        transform="translate(-3.333-1.666)" />
                    <path
                        d="M13.833,16.667a.833.833,0,0,0,.833-.833v-5a.833.833,0,0,0-1.667,0v5A.833.833,0,0,0,13.833,16.667Z"
                        transform="translate(-4-1.666)" id="0" />
                    <use transform="translate(3.333)" xlink:href="#0" />
                </svg>
            </span>
            {{'FileUpload.delete' | translate}}
        </button>
    </div>
</div>