export enum Language {
  French = 1,
  Spanish = 2,
  English = 3,
  Portuguese = 4
}

export const LanguageValues = Object.values(Language).filter(value => !isNaN(+value)) as Language[];

export function mapLanguageToTranslationKey(language: Language): string {
  switch (language) {
    case Language.Spanish:
      return 'language.spanish';
    case Language.English:
      return 'language.english';
    case Language.Portuguese:
      return 'language.portuguese';
    case Language.French:
      return 'language.french';
    default:
      return 'language.english';
  }
}
