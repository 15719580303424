<div role="dialog" class="c-dialog" aria-labelledby="titleDialog">
    <header class="c-dialog__header">
        <h1 class="c-dialog__title">{{'UpdatePasswordDialog.title' | translate}}</h1>
        <button mat-button (click)="closeModal()" class="c-dialog__close" attr.aria-label="{{'UpdatePasswordDialog.closeModalWindow' | translate}}">
            <span aria-hidden="true">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11.314 11.313">
                    <path
                        d="m319.76 417.78l-4.243-4.242-4.243 4.242-1.414-1.414 4.243-4.243-4.243-4.242 1.414-1.414 4.243 4.242 4.243-4.242 1.414 1.414-4.243 4.242 4.243 4.243"
                        transform="translate(-309.86-406.47)" />
                </svg>
            </span>
        </button>
    </header>
    <div class="c-dialog__content u-pb-1">
        <div>
            <h2 class="c-dialog__subtitle">{{'UpdatePasswordDialog.title' | translate}}</h2>
            <p class="o-text">{{'UpdatePasswordDialog.intro' | translate}}</p>
        </div>
        <div class="c-form">
            <div class="row">
                <div class="col-lg-12">
                    <p class="c-fields__legend">{{'UpdatePasswordDialog.oldPassword' | translate}}</p>
                    <mat-form-field floatLabel="never">
                        <input type="{{typeOldPassword ? 'text' : 'password' }}" matInput [(ngModel)]="oldPassword" class="c-form__input" placeholder="{{'UpdatePasswordDialog.oldPassword' | translate}}" />
                        <button type="button" (click)="toggleOldPassword()" class="c-login__toggle-pass" attr.aria-label="{{'UpdatePasswordDialog.showOrHidePassword' | translate}}">
                            <span *ngIf = "!isOldShow" aria-hidden="true">
                                <img src="assets/ico-eye-close.svg" alt="" loading="lazy" />
                            </span>
                            <span *ngIf = "isOldShow" aria-hidden="true">
                                <img src="assets/ico-eye-open.svg" alt="" loading="lazy" />
                            </span>
                        </button>
                    </mat-form-field>
                </div>
                <div class="col-lg-12">
                    <p class="c-fields__legend">{{'UpdatePasswordDialog.newPassword' | translate}}</p>
                    <mat-form-field floatLabel="never">
                        <input type="{{typeNewPassword ? 'text' : 'password' }}" matInput [(ngModel)]="newPassword" class="c-form__input" placeholder="{{'UpdatePasswordDialog.newPassword' | translate}}" />
                        <button type="button" (click)="toggleNewPassword()" class="c-login__toggle-pass" attr.aria-label="{{'UpdatePasswordDialog.showOrHidePassword' | translate}}">
                            <span *ngIf = "!isNewShow" aria-hidden="true">
                                <img src="assets/ico-eye-close.svg" alt="" loading="lazy" />
                            </span>
                            <span *ngIf = "isNewShow" aria-hidden="true">
                                <img src="assets/ico-eye-open.svg" alt="" loading="lazy" />
                            </span>
                        </button>
                    </mat-form-field>
                </div>
                <div class="col-lg-12">
                    <p class="c-fields__legend">{{'UpdatePasswordDialog.repeatedNewPassword' | translate}}</p>
                    <mat-form-field floatLabel="never">
                        <input type="{{typeRepeatedPassword ? 'text' : 'password' }}" matInput [(ngModel)]="repeatedNewPassword" class="c-form__input" placeholder="{{'UpdatePasswordDialog.repeatedNewPassword' | translate}}" />
                        <button type="button" (click)="toggleRepeatedPassword()" class="c-login__toggle-pass" attr.aria-label="{{'UpdatePasswordDialog.showOrHidePassword' | translate}}">
                            <span *ngIf = "!isRepeatedShow" aria-hidden="true">
                                <img src="assets/ico-eye-close.svg" alt="" loading="lazy" />
                            </span>
                            <span *ngIf = "isRepeatedShow" aria-hidden="true">
                                <img src="assets/ico-eye-open.svg" alt="" loading="lazy" />
                            </span>
                        </button>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </div>
    <footer class="c-dialog__footer flex-end">
        <button [disabled]="!isFormValid" (click)="updatePassword()" class="c-button c-button--green u-align-right"
            mat-button>{{'UpdatePasswordDialog.update' | translate}}</button>
    </footer>
</div>